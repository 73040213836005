<template>
  <v-container>
    <span class="text-h4">Fichadas</span>
    <v-divider></v-divider>
    <v-row
      ><v-col>
        <FichadasFilter
          @fichadas-changed="sendFichadas"
          :pageNumber="pageNumber"
        >
        </FichadasFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <FichadasTable
          ref="fichadasTable"
          :fichadas="this.fichadas.results"
          :fichadasCount="this.fichadas.count"
          @paginate="handlePagination"
        >
        </FichadasTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FichadasTable from "../components/FichadasTable";
import FichadasFilter from "../components/FichadasFilter.vue";

export default {
  name: "Fichadas",
  components: {
    FichadasTable,
    FichadasFilter,
  },
  data() {
    return {
      fichadas: [],
      pageNumber: "1",
    };
  },
  methods: {
    sendFichadas(fichadas) {
      this.$refs.fichadasTable.loading = false;
      this.fichadas = fichadas;
    },
    handlePagination(pageNumber) {
      this.pageNumber = pageNumber;
    },
  },
};
</script>
